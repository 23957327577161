import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginSign: false,
    // token: 'DCC5B4C3D37038DE62AB3C7531E528C3A94DC0F5AE7ACF04BFCFF97A567AABD5'
    token: '',
  },
  getters: {
    getToken: (state) => {
      if (state.token) {
        return state.token
      } else {
        return  ""
        // 不要使用logintoken
        // return localStorage.getItem("loginToken");
        // return document.cookie
      }
    }
  },
  mutations: {
    changeState (state, property) {
      state.loginSign = property.success;
      state.token = property.token;
    //   localStorage.setItem("loginToken", property.token);
      // document.cookie = property.token
    },
    logOut(state){
        state.loginSign = false;
        state.token = "";
        // localStorage.setItem("loginToken","")
    }
  },
  actions: {},
  modules: {},
});
