import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  {
    path: '/login',
    component: () => import('@/views/Login.vue')
  },
  // { path: '/test', component: () => import('@/views/overview/Test.vue') },
  {
    path: '/home',
    redirect: '/bigscreen',
    component: () => import('@/views/Home.vue'),
    children: [
      { 
        path: '/bigscreen', 
        component: () => import('@/views/overview') 
      },
      { 
        path: '/dock', 
        component: () => import('@/views/wharf') 
      },
      { 
        path: '/waterway', 
        component: () => import('@/views/channel') 
      },
      {
        path: '/permission',
        redirect: '/uright',
        component: () => import('@/views/power'),
        children: [
          { 
            path: '/uright', 
            component: () => import('@/views/power/rights') 
          },
          { 
            path: '/urole', 
            component: () => import('@/views/power/roles') 
          }
        ]
      },
      { 
        path: '/underwater', 
        component: () => import('@/views/seabed') 
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

// 导航守卫
router.beforeEach((to,from,next)=>{
    let path = to.path
    let token = store.getters.getToken
    //  "/"会跳转到"login"
    if(path === "/login" || path === "/"){
        next()
    }else if(token === null || token === ''){
        // 未登录状态访问任何路由
        next("/")
    }else{
        // 登录状态访问路由，放行
        next()
    }
})


export default router
