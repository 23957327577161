import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 导入 element 部分组件
import './plugins/element.js'
// 导入字体样式表
import './assets/css/font.css'
// 导入全局样式表
import './assets/css/global.css'
// 导入video.js样式表
import "video.js/dist/video-js.css"
// 导入video自定义样式
import "./assets/css/video.css"
// 导入自定义分页器样式
import "./assets/css/pagination.css"
import axios from 'axios'

// console.log(router.currentRoute);
const JsonUrl = window.location.origin + window.location.pathname
if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = JsonUrl
}

Vue.prototype.$http = axios

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
