import Vue from 'vue'
import {
    Form, FormItem, Button, Input, Message, Container,
    Aside, Header, Main, Menu, MenuItem, Breadcrumb,
    BreadcrumbItem, Card, Checkbox, CheckboxGroup, Radio,
    Table, TableColumn, Select, Option, Tooltip, Dialog,
    Col, Row, Tree, Carousel, CarouselItem, Slider,
    Autocomplete, InputNumber, DatePicker, ColorPicker,Pagination
} from 'element-ui'

Vue.use(Form)
Vue.use(FormItem)
Vue.use(Button)
Vue.use(Input)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Header)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Radio)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tooltip)
Vue.use(Dialog)
Vue.use(Col)
Vue.use(Row)
Vue.use(Tree)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Slider)
Vue.use(Autocomplete)
Vue.use(InputNumber)
Vue.use(DatePicker)
Vue.use(ColorPicker)
Vue.use(Pagination)


Vue.prototype.$message = Message
